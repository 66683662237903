import React from "react";
import Post from "../components/Post";

import comparisonImage from "./assets/002/farmhandgo-prototype-comparison.png";

const links = {
  farmhandGo: "https://rainbowcow-studio.itch.io/farmhand-go",
  twitterProfile: "https://twitter.com/onehumblepot",
};

export default function () {
  return (
    <Post title={"play Farmhand Go!"} postDate={"April 4, 2022"}>
      <p>
        Nearly 6 months after my first post on this domain I have spent a lot of
        time working on various projects, and absolutely no time writing about
        them! This is usually the point where I deter myself from pressing
        forward, but I'm not going to do that this time around. I did however
        join Twitter in the meantime if you're looking for someone new to follow
        today.
      </p>
      <p>
        <img src={comparisonImage} alt="comparison of prototype vs now" />
      </p>
      <p>
        I spent most of my time in the last six months working in Godot Engine
        to build "Farmhand Go!". This was an excellent learning experience for
        how Godot works, though of course I wish I had known everything I know
        now before I started that project. The most difficult to learn part was
        how to make the game responsive for various resolutions. Interface
        building for a game felt much more complicated than it does for a
        website. Audio work is no joke either! Finding sound effects is a really
        challenging, and time consuming, task.
      </p>
      <p>
        This game started with a very simple idea from{" "}
        <a href="https://github.com/jeremyckahn" target="_blank">
          Jeremy Kahn
        </a>{" "}
        after we started talking about building a mobile game that took place in
        the{" "}
        <a href="https://www.farmhand.life/" target="_blank">
          Farmhand
        </a>{" "}
        universe. We purposely started with the smallest idea we could possibly
        think of, and a weekend gamejam approach to building a prototype.
      </p>
      <p>
        I think the first pitch was something like tap plots to water crops,
        harvest them to earn money, and fend off crows in the process. We had no
        sound, and the most minimal art, but the prototype was actually kinda
        fun after just a few hours of work. We felt that if this could be fun
        and slightly addicting while the game had cheap graphics and no audio at
        all then it should be pretty great once it was polished!
      </p>
      <p>
        I feel obliged to say that I knew making a game was a lot more work than
        it seems like it's going to be so keeping scope as small as possible
        (and even smaller) was key to building this game, but this was still
        more work than I thought it would be! Honestly, building the core game
        loop was easy compared to making the game feel "full". Adding scenery,
        particle effects, audio, and motion, are all very subjective and very
        critical.
      </p>
      <p>
        Making the game look good was actually something I didn't think I would
        be able to do at all without finding a teammate that was an artist, or
        hiring someone to draw some custom assets. However, I found that the
        assets on itch and OpenGameArt were actually way higher quality than I
        was expecting. Purchasing some crops, and some scenery (and then
        learning how to use the Godot tile editor) was what made the game stop
        looking like a prototype and start feeling like a 'real' game. Of
        course, if it was boring to play before it was still going to be boring,
        but having nice assets made it so much more enjoyable to show the game
        to other people.
      </p>
      <p>
        Sometime towards the end of "Farmhand Go!" I felt very empowered with my
        Godot skills and set out to build some prototypes of other ideas I have.
        Now that "Farmhand Go!" has been off my plate for a little bit I have
        been thinking a lot about what to do with these prototypes. I also have
        some web projects in mind. So, I have some indecision to deal with for
        now and might jump between some things for a bit.
      </p>
      <p>
        <a href={links.farmhandGo} target="_blank">
          Play Farmhand Go! for free on itch
        </a>
        .
      </p>
      <p>
        <a href={links.twitterProfile} target="_blank">
          Follow me (@onehumblepot) on the Twitter
        </a>
        .
      </p>
    </Post>
  );
}

import React from "react";
import Post from "../components/Post";

import titleScreen from "./assets/003/title-screen.png";
import changeset from "./assets/003/changeset.png";

const links = {
  itchioProfile: "https://ahhsumx.itch.io/",
  playDemo: "https://ahhsumx.itch.io/hot-spud",
  twitterProfile: "https://twitter.com/onehumblepot",
};

export default function () {
  return (
    <Post title={"demo released for 'Hot Spud'"} postDate={"April 9, 2022"}>
      <p>
        Today I added a new project to{" "}
        <a href={links.itchioProfile} target="_blank">
          my itch.io page
        </a>{" "}
        containing a demo release of a game I call "Hot Spud". I started to make
        this game so very long ago. It was actually the project that taught me I
        didn't know nearly as much as I thought I did about programming a game.
        This all started in September 2020...
      </p>

      <p>
        <img src={titleScreen} alt="hot spud title screen" />
      </p>

      <p>
        I genuinely enjoy playing a well made brick breaking game, but the
        keyword there is well made. The game "Shatter" comes to mind as one of
        the most recent ones I've found, and I also enjoyed the spin on the
        genre that is "Wizorb". There are probably hundreds of lifeless brick
        breaking games out there though (and probably some other good ones I
        haven't seen.)
      </p>

      <p>
        So, Hot Spud for me was originally a project simply called "bricks" and
        I hoped to both learn Godot Engine, and build this brick breaking game.
        The learning curve for Godot was not too bad, but I quickly learned that
        I had no idea how to organize game code. The code quickly got out of
        control and became something I couldn't work with.
      </p>

      <p>
        To make matters worse, at one point I overwrote a week of progress with
        a backup by accident and that really put a halt to working on this
        project. Thankfully though, I worked on several other projects after
        semi-abandoning this one, and the practice that brought me gave me the
        ability to fix the mess I had made.
      </p>

      <p>
        <img
          src={changeset}
          alt="385 changed files with 16,511 additions and 1,948 deletions"
        />
      </p>

      <p>
        Sometime in February 2022 I decided I wanted a refactoring challenge,
        and I chose this game to be that project. Between February 22 and April
        6 there were 385 changed files with 16,511 additions and 1,948 deletions
        over 65 commits! Some of that is the generated scene files but it's
        still a lot of stuff. Practically an entire rewrite honestly.
      </p>

      <p>
        The best part about this is how easy the game is to work with now from a
        development perspective. Adding stages, powerups, obstacles, or even an
        entire new adventure is straightforward now and all the game objects
        operate independently. Untangling things was actually kind of fun!{" "}
      </p>

      <p>
        However, I am not sure what the end goal is for this game since I
        approached it with such a heavy emphasis on refactoring. So that's why I
        decided to post the game on itch today for anyone and everyone to check
        out. I would love to hear your feedback about the game through itch
        comments, or on Twitter, if you have time to play. Thanks for reading!
      </p>

      <p>
        <a href={links.playDemo} target="_blank">
          Play Hot Spud on itch
        </a>
      </p>

      <p>
        <a href={links.twitterProfile} target="_blank">
          Follow me on Twitter
        </a>
      </p>
    </Post>
  );
}

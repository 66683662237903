import React from "react";
import Layout from "../components/Layout";

import indieGameDev from "./posts/001-indie-game-dev";
import playFarmhandGo from "./posts/002-play-farmhand-go";
import hotSpudDemoReleased from "./posts/003-hot-spud-demo-released";

const posts = [hotSpudDemoReleased, playFarmhandGo, indieGameDev];

export default function Home() {
  return (
    <Layout>
      {posts.map((P, i) => (
        <div key={i}>
          <P />
        </div>
      ))}
    </Layout>
  );
}

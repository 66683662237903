import React from "react";
import Post from "../components/Post";

export default function () {
  return (
    <Post title={"indie game dev"} postDate={"october 13, 2021"}>
      <p>
        Being an indie game developer is a notoriously hard job to make money
        doing. Most people doing it are doing it as their second (or maybe
        third) job, and never making more than a cup of coffee worth of money.
        Why is that?
      </p>
      <p>
        Well, in short, I think it’s because games are ridiculously hard to
        build, there are tons of them out there to compete against, and nobody
        wants to pay for them at the end of the day anyways. Even $60 for a AAA
        title game is a steal when you consider the hundreds of thousands (or
        more) of man hours it took to build. A simple Minesweeper clone would
        probably take over a hundred hours to truly complete to the point of
        releasing.
      </p>
      <p>
        So, being an indie dev you already have to set your scope low (if you
        plan to finish), your price tag even lower, and be prepared to do a lot
        of work. Then you have to market a lot! That’s actually one of the most
        difficult, and costly parts of the whole thing.
      </p>
      <p>
        This blog is about my personal journey as a hobbyist indie game dev. I
        work full time, 5 days a week, as a software engineer and work on
        personal development efforts before, and after that job. This blog will
        contain posts of various types about the things I spend my free time
        working on. Hopefully we all learn something interesting on the journey!
        That’s what it’s all about after all, right?
      </p>
      <p>
        Current focus: Farmhand Go! A mobile game currently in the prototyping
        stage that takes place in the{" "}
        <a href="https://farmhand.life" target="_blank">
          Farmhand
        </a>{" "}
        universe.
      </p>
    </Post>
  );
}

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import bannerImage from "../assets/onehumblepotato-banner.png";
import bannerSquare from "../assets/onehumblepotato-square.png";

import "./layout.css";

export default function Layout({ children, className }) {
  return (
    <div className={classNames(className)}>
      <div className="header-banner">
        <h2>
          <img
            src={bannerImage}
            alt="one humble potato logo banner"
            className="logo-banner"
          />
        </h2>
      </div>
      <div className="area-content">{children}</div>
    </div>
  );
}

Layout.propTypes = {
  className: PropTypes.string,
};
